<template>
  <div style="padding: 2rem 3rem; text-align: left">
    <div class="field">
      <label class="label">Número de cédula</label>
      <div class="control">
        <input
          :class="['input', $v.form.cedula.$error ? 'is-danger' : '']"
          type="text"
          placeholder="Número de cédula"
          v-model="form.cedula"
        />
      </div>
      <p v-if="$v.form.cedula.$error" class="help is-danger">
        La cédula es inválida
      </p>
    </div>
    <div class="field">
      <label class="label">Correo Electrónico</label>
      <div class="control">
        <input
          :class="['input', $v.form.email.$error ? 'is-danger' : '']"
          type="text"
          placeholder="Correo Electrónico"
          v-model="form.email"
        />
      </div>
      <p v-if="$v.form.email.$error" class="help is-danger">
        El email es inválido
      </p>
    </div>
    <div class="field">
      <label class="label">Teléfono</label>
      <div class="control">
        <input
          :class="['input', $v.form.phone.$error ? 'is-danger' : '']"
          type="text"
          placeholder="Teléfono"
          v-model="form.phone"
        />
      </div>
      <p v-if="$v.form.phone.$error" class="help is-danger">
        El número de Teléfono es inválido
      </p>
    </div>
    <div class="field">
      <label class="label">Tipo de trámite</label>
      <div class="control">
        <select
          :class="['input', $v.form.message.$error ? 'is-danger' : '']"
          v-model="form.message"
          placeholder="Nombre del trámite"
        >
          <option disabled value="">Seleccione un trámite</option>
          <option value="Inscripción a partido en formación">
            Inscripción a partido en formación
          </option>
          <option value="Renuncia a partido">Renuncia a partido</option>
          <option value="Inscripción a partido">Inscripción a partido</option>
          <option value="Cambio de residencia">Cambio de residencia</option>
          <option value="Anmar dulegaya gui bemarbo sunmakoe (Guna)">
            Anmar dulegaya gui bemarbo sunmakoe (Guna)
          </option>
          <option value="Ni tua kanti Ngabere (Ngabe)">
            Ni tua kanti Ngabere (Ngabe)
          </option>
         
        </select>
      </div>
    </div>
    <b-alert v-if="showDisclaimer" show variant="primary">
      <h3>Notificación</h3>
      <ul>
        <li>
          Los Electores residentes en Panamá, que no se encontraran en el país
          el día de la elección general deberán presentar:
          <strong
            >Una copia del Pasaje Aéreo u otro documento que pruebe ese
            hecho.</strong
          >
        </li>
      </ul>
      <ul>
        <li>
          Los Electores que por motivo de trabajo soliciten su inscripción
          deberán presentar:
          <strong>Copia de su carne laboral.</strong>
        </li>
      </ul>
      <ul>
        <li>
          Los electores que declaren una o más discapacidad deberán presentar:
          <strong
            >Una Certificación Médica (Que indique el tipo de
            discapacidad)</strong
          >
        </li>
      </ul>
      <em
        >Nota: Es un requisito indispensable, adjuntar la documentación antes
        citadas dependiendo de cada caso para continuar su trámite de
        Inscripción de Electores Residentes en el País (REVA). De conformidad al
        Artículo 326 del Decreto 29 del 30 de mayo de 2022 y sus
        modificaciones.</em
      >
      <div class="field mt-3">
        <input
          class="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          v-model="form.disclaimer"
        />
        <label
          class="form-check-label"
          for="inlineCheckbox2"
          style="margin-left: 5px; margin-top: 10px"
        >
          Aceptar Condiciones.</label
        >
      </div>
    </b-alert>
    <div class="field">
      <label class="label">Siga las siguientes instrucciones:</label>
      <div class="control text-center">
        <img width="350px" src="../assets/digital-identity.jpg" />
      </div>

      <ul>
        <li>Ubicarse delante de un fondo claro</li>
        <li>Cara bien iluminada</li>
        <li>Ubique el celular o webcam a la altura de su cara</li>
        <li>Imagen enfocada y nítida</li>
      </ul>
    </div>
    <div class="legal-advice">
      AVISO LEGAL: Utilizar la identidad de otra persona para obtener algún
      beneficio, a través de un medio informático, es una agravante del delito
      de estafa tipificado en los artículos 220 y 221 del Código Penal, que
      contempla penas de hasta 10 años de prisión.
    </div>
    <div class="field">
      <input
        class="form-check-input"
        type="checkbox"
        id="inlineCheckbox1"
        value="chepolitics"
        v-model="form.politics"
      />
      <label
        class="form-check-label"
        for="inlineCheckbox1"
        style="margin-left: 5px; margin-top: 10px"
      >
        Aceptar Términos y Condiciones.</label
      >
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import store from "../store/";

const DISCLAIMERS = ["Inscripción de Electores Residentes en el País (REVA)"];

export default {
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        cedula: "",
        message: "",
        politics: null,
        disclaimer: null,
        phone: "",
      },
    };
  },
  validations: {
    form: {
      cedula: {
        required,
        isnumeric() {
          var pattern =
            /^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,7})$/i;
          if (this.form.cedula.match(pattern)) {
            return true;
          } else {
            return false;
          }
        },
      },
      message: {
        required,
      },
      politics: {
        required,
      },
      disclaimer: {
        isVerification() {
          if (this.activeDisclaimer) return this.form.disclaimer ? true : false;
          return true;
        },
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
    },
  },
  watch: {
    $v: {
      handler: function (val) {
        if (!val.$invalid) {
          store.commit("setCedula", this.form.cedula);
          store.commit("setEmail", this.form.email);
          store.commit("setPhone", this.form.phone);
          store.commit("setProcess", this.form.message);
          this.$emit("can-continue", { value: true });
        } else {
          this.$emit("can-continue", { value: false });
          setTimeout(() => {
            this.$emit("change-next", { nextBtnValue: false });
          }, 3000);
        }
      },
      deep: true,
    },

    clickedNext(val) {
      console.log(val);
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  computed: {
    showDisclaimer: function () {
      const values = DISCLAIMERS.find((n) => n === this.form.message);
      return (this.activeDisclaimer = values ? true : false);
    },
  },
  methods: {
    track() {
      this.$gtag.pageview("/StepOne");
    },
    cameras() {
      //initializing the deviceId with null
      let deviceId = null;
      //returns a promise that resolves to a list of
      //connected devices
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const cams = devices.filter((device) => device.kind === "videoinput");
          console.log(cams.length);

          if (!(cams.length > 0)) {
            console.log("bad");
            this.$gtag.event("StepOne", { method: "NoCamera" });
            alert(
              "Su equipo no cuenta con cámara disponible, por favor conecte una o utilice otro dispositivo"
            );
          }
        })
        .catch((err) => {
          //handle the error
          console.log(err);
        });
    },
  },
  mounted() {
    if (!this.$v.$invalid) {
      this.$emit("can-continue", { value: true });
    } else {
      this.$emit("can-continue", { value: false });
    }

    this.cameras();

    this.$gtag.event("StepOne", { method: "Web" });
  },
};
</script>

<style scoped>
.legal-advice {
  background-color: #e9ecef;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>