import Vue from 'vue'
import Vuex from 'vuex'
import * as faceapi from 'face-api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cedula: '',
    loading: false,
    loaded: false,
    email: '',
    department:'',
    process:'',
    phone: ''
  },
  getters: {

  },
  mutations: {
    setCedula(state, cedula) {
      state.cedula = cedula
      console.log(cedula);
    },
    setLoading(state, loading) {
      state.loading = loading
      console.log(loading);
    },
    setLoaded(state, loaded) {
      state.loading = loaded
      console.log(loaded);
    },
    setEmail(state, email) {
      state.email = email
    },
    setProcess(state, process) {
      state.process = process
    },
    setPhone(state, phone) {
      state.phone = phone
    },
  },
  actions: {
    async load({ commit, state }) {
      if (!state.loaded) {
        return Promise.all([

          faceapi.nets.tinyFaceDetector.loadFromUri('https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js@0.22.2/weights/'),
          faceapi.nets.faceLandmark68Net.loadFromUri('https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js@0.22.2/weights/'),
          faceapi.nets.faceRecognitionNet.loadFromUri('https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js@0.22.2/weights/'),
          faceapi.nets.faceExpressionNet.loadFromUri('https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js@0.22.2/weights/')

        ]).then(() => {
          commit('setLoading', false)
          commit('setLoaded', true)
        })
      }
    }
  },
  modules: {
  }
})
