<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.4.1/css/bulma.min.css"
    />
    <section class="hero is-fullheight">
      <b-overlay :show="loading" rounded="sm">
      <div class="hero-body">
        <div class="container">
          <div class="column is-4 is-offset-4">
            <div class="has-text-centered">
              <img alt="Vue logo" src="../assets/logo.png" />
            </div>
            <div class="box mt-4">

                <div class="field">
                  <label class="label">Cédula</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      autofocus=""
                      v-model="form.cedula"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label"> Correo Electrónico</label>
                  <div class="control">
                    <input class="input" type="email" v-model="form.email" />
                  </div>
                </div>
                <button class="button is-block is-info is-fullwidth mt-4" @click="saveVideo()">
                  Solicitar
                </button>

            </div>
          </div>
        </div>
      </div>
      
       <template v-slot:overlay>
            <div>
              <b-icon
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">Cargando...</p>
            </div>
          </template>
        </b-overlay>
    </section>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        cedula: null,
        email: null,
      },
      loading:false
    };
  },
  methods:{
    async saveVideo() {
      this.loading=true;
      var axios = require("axios");
      var qs = require("qs");
      var data = JSON.stringify({
        documentId: this.form.cedula,
        email: this.form.email
      });

      var config = {
        method: "post",
        url: "https://bio-te-api.azurewebsites.net/api/Videos/video-upload/",
         headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      };
      
      await axios(config)
        .then((response) => {
            this.loading = false;
            alert("El video se está procesando. Se enviará un email cuando esté subido a AWS");

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
        this.loading=false;
    }
  }
};
</script>