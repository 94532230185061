<template>
  <div>
    <NavBar />
    <b-container fluid class="p-0">
      <slot />
    </b-container>
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Home from "../components/Home";

export default {
  name: "LayoutDefault",
  components: {
    NavBar,
    Home,
  },
};
</script>

<style>
html,
.container-fluid {
  background-color: #e6e6e6;
}

h5 {
  text-align: center;
  color: #005ba1;
  font-weight: 700 !important;
}

hr {
  border: 1.2px solid !important;
}

.toast:not(.show) {
  display: block !important;
}

/* button */
.form-btn {
  padding: 0.3rem 3rem !important;
  font-weight: 600 !important;
  background-color: #005ba1 !important;
}

.modal-btn {
  font-weight: 600 !important;
  background-color: #005ba1 !important;
}

/* upload */
.upload-logo {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2300000038' stroke-width='5' stroke-dasharray='6%2c 20' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 10px !important;
  background-color: #e6e6e680 !important;
  text-align: center !important;
  height: unset !important;
  border: 0 !important;
}

.clearButton,
.plus {
  display: none;
}

.mainMessage {
  padding: 0.4rem;
  font-weight: 600;
  background-color: #005ba1;
  color: #fff;
  width: 135px;
  margin: auto;
  border-radius: 0.2rem;
  font-size: 0.875rem;
}

.beforeUpload .icon {
  width: 110px !important;
  padding: 1.5rem 0;
  color: grey;
  filter: opacity(0.1);
}

/* input */
.form-fields {
  font-weight: 600;
  color: #000000c2;
  font-size: 15px;
}

.form-control {
  background-color: #e6e6e680 !important;
}

/* table */
.table > :not(:first-child) {
  border-top: 2px solid #005ba1 !important;
}

.table > thead > tr > th {
  font-size: 15px;
  color: #005ba1;
  padding-bottom: 0.2rem;
  padding-left: 0;
}

.table > tbody > tr {
  vertical-align: middle;
}

.table > tbody > tr > td {
  font-size: 14px;
  font-weight: 500;
}

.table > tbody > tr > td:last-child {
  text-align: center !important;
  color: #005ba1;
  cursor: pointer;
}
</style>